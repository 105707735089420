$base-color: #22ad9b;
$base-color-darker: #136459c0;
$base-color-hex: rgba(34, 173, 154, 0.4);
$background-color: #f5f5f5;
$secondary-color: #eef1f8;
$color_1: #012970;
$color_2: #444444;
$color_3: #fff;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Nunito";
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 30px;
}

html,
body {
  background-color: $background-color;
}

$theme-colors: (
  "primary": $base-color,
);

@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}

@import "node_modules/bootstrap/scss/bootstrap";
