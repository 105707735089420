@import "../../styles/global.scss";

.hero {
  width: 100%;
  height: 100vh;
  background: url(../../assets/hero-bg.png) top center no-repeat;
  background-size: cover;
  h1 {
    margin: 0;
    font-size: 48px;
    font-weight: 700;
    color: $base-color;
  }
  h2 {
    color: $color_2;
    margin: 15px 0 0 0;
    font-size: 26px;
  }
  .btn-get-started {
    margin-top: 30px;
    line-height: 0;
    padding: 20px 40px;
    border-radius: 4px;
    transition: 0.5s;
    color: $color_3;
    background: $base-color;
    text-decoration: none;
    box-shadow: 0px 5px 30px $base-color-hex;
    font-family: Inter;

    span {
      font-family: Inter;
      font-weight: 600;
      font-size: 16px;
      letter-spacing: 1px;
    }
    i {
      margin-left: 5px;
      font-size: 18px;
      transition: 0.3s;
    }
    &:hover {
      i {
        transform: translateX(5px);
      }
      filter: brightness(0.8);
    }
  }
  .hero-img {
    text-align: right;
    max-width: 65vh;
    position: absolute;
    bottom: 0;
    right: 0;
    margin: auto auto 0; /* position to bottom and center */
  }
}
@media (min-width: 1024px) {
  .hero {
    background-attachment: fixed;
  }
}
@media (max-width: 991px) {
  .hero {
    height: auto;
    padding: 120px 0 60px 0;
    .hero-img {
      text-align: center;
      position: relative;
      margin-top: 80px;
      img {
        width: 80%;
      }
    }
  }
}
@media (max-width: 768px) {
  .hero {
    text-align: center;
    h1 {
      font-size: 32px;
    }
    h2 {
      font-size: 24px;
    }
    .hero-img {
      img {
        width: 100%;
        margin-left: 30px;
      }
    }
  }
}
/*--------------------------------------------------------------
# Values
--------------------------------------------------------------*/
.about {
  margin: 0 auto;

  .content {
    background-color: $secondary-color;
    padding: 40px;
  }
  h3 {
    font-size: 14px;
    font-weight: 700;
    color: $base-color;
    text-transform: uppercase;
  }
  h2 {
    font-size: 24px;
    font-weight: 700;
    color: $base-color;
  }
  p {
    margin: 15px 0 30px 0;
    line-height: 24px;
  }
  img {
    width: 25rem;
    object-fit: cover;
    height: 500px;
    transition: 0.5s cubic-bezier(0.6, 0.03, 0.28, 0.98);
    transform: scale(0.8);
  }

  img:hover {
    transform: scale(0.9);
    transition: 1.2s cubic-bezier(0.6, 0.03, 0.28, 0.98);
  }

  .btn-read-more {
    line-height: 0;
    padding: 15px 40px;
    border-radius: 4px;
    transition: 0.5s;
    color: #fff;
    background: $base-color;
    box-shadow: 0px 5px 25px rgba(65, 84, 241, 0.3);
    span {
      font-family: "Nunito", sans-serif;
      font-weight: 600;
      font-size: 16px;
      letter-spacing: 1px;
    }
    i {
      margin-left: 5px;
      font-size: 18px;
      transition: 0.3s;
    }
    &:hover {
      i {
        transform: translateX(5px);
      }
    }
  }
}

#contact {
  margin-top: 2em;
  margin-bottom: 2em;

  .container {
    background-color: $secondary-color;
    padding: 40px;

    h2 {
      font-family: "Nunito";
      color: $base-color;
      font-weight: 600;
      text-align: center;
    }

    h3 {
      font-size: 14px;
      font-weight: 700;
      color: $base-color;
      text-transform: uppercase;
      text-align: center;
    }

    .icons {
      text-align: center;

      i {
        width: fit-content;
        color: $base-color;
        margin: 1em 1em;
        cursor: pointer;
      }

      i:hover {
        color: $base-color-darker;
      }
    }
  }
}

#location {
  .container {
    background-color: $secondary-color;
    padding: 40px;

    .title {
      font-family: "Nunito";
      color: $base-color;
      font-weight: 600;
      text-align: center;
    }

    .content {
      h2 {
        font-size: 24px;
        font-weight: 700;
        color: $base-color;
      }
    }

    h3 {
      font-size: 14px;
      font-weight: 700;
      color: $base-color;
      text-transform: uppercase;
    }

    p {
      margin: 15px 0 30px 0;
      line-height: 24px;
    }

    #maps {
      iframe {
        min-width: 300px;
        width: 100% !important;
      }
    }

    h4 {
      text-align: center;
      font-family: "Nunito";
      color: $base-color;
    }

    p {
      font-family: "Nunito";
      color: $color_2;
      line-height: 24px;
    }
  }
}
