@import "../../styles/global.scss";

@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}
.header {
  a {
    text-decoration: none;
  }

  transition: all 0.5s;
  z-index: 997;
  padding: 20px 0;
  .logo {
    line-height: 0;
    img {
      max-height: 100px;
    }
  }
}
.header.header-scrolled {
  background: #fff;
  padding: 15px 0;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
}
.navbar {
  padding: 0;
  ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }
  li {
    position: relative;
    &:hover {
      > a {
        color: $base-color-darker;
      }
    }
  }
  a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0 10px 30px;
    font-family: "Nunito", sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: $base-color;
    white-space: nowrap;
    transition: 0.3s;
    &:focus {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 0 10px 30px;
      font-family: "Nunito", sans-serif;
      font-size: 16px;
      font-weight: 700;
      color: $base-color;
      white-space: nowrap;
      transition: 0.3s;
      i {
        font-size: 12px;
        line-height: 0;
        margin-left: 5px;
      }
    }
    i {
      font-size: 12px;
      line-height: 0;
      margin-left: 5px;
    }
    &:hover {
      color: $base-color-hex;
    }
  }
  .active {
    color: $base-color;
    &:focus {
      color: $base-color;
    }
  }
  .getstarted {
    background: $base-color;
    padding: 8px 20px;
    margin-left: 30px;
    border-radius: 4px;
    color: #fff;
    &:focus {
      background: $base-color;
      padding: 8px 20px;
      margin-left: 30px;
      border-radius: 4px;
      color: #fff;
      &:hover {
        color: #fff;
        background: $base-color-darker;
      }
    }
    &:hover {
      color: #fff;
      background: $base-color-darker;
    }
  }
  .dropdown {
    ul {
      display: block;
      position: absolute;
      left: 14px;
      top: calc(100% + 30px);
      margin: 0;
      padding: 10px 0;
      z-index: 99;
      opacity: 0;
      visibility: hidden;
      background: #fff;
      box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
      transition: 0.3s;
      border-radius: 4px;
      li {
        min-width: 200px;
        &:hover {
          > a {
            color: $base-color-darker;
          }
        }
      }
      a {
        padding: 10px 20px;
        font-size: 15px;
        text-transform: none;
        font-weight: 600;
        i {
          font-size: 12px;
        }
        &:hover {
          color: $base-color-darker;
        }
      }
      .active {
        &:hover {
          color: $base-color-darker;
        }
      }
    }
    &:hover {
      > ul {
        opacity: 1;
        top: 100%;
        visibility: visible;
      }
    }
    .dropdown {
      ul {
        top: 0;
        left: calc(100% - 30px);
        visibility: hidden;
      }
      &:hover {
        > ul {
          opacity: 1;
          top: 0;
          left: 100%;
          visibility: visible;
        }
      }
    }
  }
}

.header.active {
  background-color: $base-color;
  padding: 0;
  .navbar a {
    color: #fff;
  }
}

.mobile-nav-toggle {
  color: $base-color;
  display: none;
  font-size: 28px;
  cursor: pointer;
  line-height: 0;
  transition: 0.5s;
  margin-right: 1.5rem;
}
.mobile-nav-toggle.bi-x {
  color: #fff;
}
.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: $base-color-hex;
  transition: 0.3s;

  .mobile-nav-toggle {
    position: absolute;
    top: 15px;
    right: 15px;
  }
  ul {
    display: block;
    position: absolute;
    top: 55px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    padding: 10px 0;
    border-radius: 10px;
    background-color: #fff;
    overflow-y: auto;
    transition: 0.3s;
  }
  a {
    padding: 10px 20px;
    font-size: 15px;
    color: $base-color;
    &:focus {
      padding: 10px 20px;
      font-size: 15px;
      color: $base-color;
    }
    &:hover {
      color: $base-color-darker;
    }
  }
  .active {
    color: $base-color;
  }
  li {
    &:hover {
      > a {
        color: $base-color-darker;
      }
    }
  }
  .getstarted {
    margin: 15px;
    &:focus {
      margin: 15px;
    }
  }
  .dropdown {
    ul {
      position: static;
      display: none;
      margin: 10px 20px;
      padding: 10px 0;
      z-index: 99;
      opacity: 1;
      visibility: visible;
      background: #fff;
      box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
      li {
        min-width: 200px;
        &:hover {
          > a {
            color: $base-color;
          }
        }
      }
      a {
        padding: 10px 20px;
        i {
          font-size: 12px;
        }
        &:hover {
          color: $base-color-darker;
        }
      }
      .active {
        &:hover {
          color: $base-color-darker;
        }
      }
    }
    > .dropdown-active {
      display: block;
    }
  }
}
@media (max-width: 1366px) {
  .navbar {
    .dropdown {
      .dropdown {
        ul {
          left: -90%;
        }
        &:hover {
          > ul {
            left: -100%;
          }
        }
      }
    }
  }
}
@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }
  .navbar {
    ul {
      display: none;
    }
  }
}

.ant-modal-root {
  .ant-modal-content {
    border-radius: 10px;
  }

  .modal-container {
    text-align: center;
    border: none;
  }

  .whatsapp-wrapper {
    text-align: center;
    margin-top: 0 auto;
  }

  .whatsapp-button {
    background-color: $base-color;
    color: white;
    padding: 0.8em;
    border-radius: 10px;
    margin-left: 2em;
  }

  a {
    color: $base-color;
  }

  ul {
    list-style-type: none;
    li {
      color: $base-color;
      font-size: 1.1rem;
    }
  }
}
