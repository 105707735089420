@import "../../styles/global.scss";
@import "~react-image-gallery/styles/scss/image-gallery.scss";

.post-page {
  min-height: 100vh;
  height: 100%;
  background-color: $color_3;
  .container {
    height: 100%;

    .image-gallery {
      max-width: 50%;
      max-height: 30%;

      margin: 0 auto;
      padding-top: 30px;
      padding-bottom: 30px;

      .image-gallery-icon svg {
        width: 1rem;
      }
    }

    h1 {
      font-weight: bold;
      color: $base-color;
      margin-bottom: 24px;
    }

    p {
      color: $color_2;
      font-family: Inter;
      white-space: pre-wrap;
    }
  }
  .carousel {
    text-align: center;
    width: 300px;
    height: 300px;
    margin-bottom: 30px;

    .carousel-item {
      img {
        width: 500px;
        height: 500px;
        object-fit: contain;
      }
    }
  }
}
